export enum ActionType {
  LOADING = 'LOADING',
  LOADING_ERROR = 'LOADING_ERROR',
  ENTERPRISE_EXISTS = 'ENTERPRISE_EXISTS',
  CHANGE_FLOW = 'CHANGE_FLOW',
  FINISH_FLOWS = 'FINISH_FLOWS',
  GET_COMMERCIAL_LIST = 'GET_COMMERCIAL_LIST',
  SET_COUNTRIES = 'SET_COUNTRIES',
  SET_FORM = 'SET_FORM',
  SET_ID_ENTERPRISE = 'SET_ID_ENTERPRISE',
  SET_SUCCESS = 'SET_SUCCESS',
}
