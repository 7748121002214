import {AppState, initialState} from './state'
import type {AppAction} from 'actions/action'
import {ActionType} from 'actions/types'

const reducer = (state: AppState = initialState, action: AppAction) => {
  switch (action.type) {
    case ActionType.FINISH_FLOWS:
      return {
        ...state,
        finishFlows: true

      }
    case ActionType.LOADING:
      return {
        ...state,
        isFetching: true,
      }
    case ActionType.LOADING_ERROR:
      return {
        ...state,
        isFetching: false,
      }
    case ActionType.ENTERPRISE_EXISTS:
      return {
        ...state,
        isFetching: false,
        enterpriseExits: action.payload,
      }

    case ActionType.CHANGE_FLOW:
      return {
        ...state,
        flow: action.payload,
      }

    case ActionType.GET_COMMERCIAL_LIST:
      return {
        ...state,
        commercialList: action.payload,
        isFetching: false,
      }
    case ActionType.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
        isFetching: false,
      }

    case ActionType.SET_FORM:
      return {
        ...state,
        initialForm: {
          ...state.initialForm,
          ...action.payload,
        },
      }

    case ActionType.SET_ID_ENTERPRISE:
      return {
        ...state,
        initialForm: {
          ...state.initialForm,
          enterpriseId: action.payload,
        },
      }

    case ActionType.SET_SUCCESS:
      return {
        ...state,
        succes: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
