import {Col, Typography} from 'antd'
import {ModalCustom} from 'components'
import {useState} from 'react'
import styled from 'styled-components'
import {custom} from 'theme/palette'

const { Title, Text, Link } = Typography

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`

const ColCustom = styled(Col)`
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
`
const data = {
  title: `Solicitud de pase a producción`,
  body: `Para solicitar tu pase a producción tienes que haber concluido satisfactioriamente las pruebas ¿Estas seguro que quieres solicitarlo?`,
  pruebaType: 'paseAprod',
}
const FifthStep = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalCloseRequest = (): void => {
    setIsModalOpen(!isModalOpen)
  }
  return (
    <>
      <ModalCustom
        title={data.title}
        pruebaType={data.pruebaType}
        body={data.body}
        isOpen={isModalOpen}
        onCloseRequest={onModalCloseRequest}
      />
      <Col>
        <Title level={2}>5. Pase a producción</Title>
        <ColCustom>
          <TextCustom>
            Una vez se hayan realizado las pruebas satisfactoriamente, podemos
            concretar tu pase a producción solicitándolo{' '}
            <Link onClick={onModalCloseRequest}>aquí</Link> . Te estaremos
            respondiendo en un plazo no mayor a 24 horas o escríbenos al correo{' '}
            <Link href="mailto:integraciones@chazki.com" target="_blank">
              integraciones@chazki.com
            </Link>
          </TextCustom>
        </ColCustom>
      </Col>
    </>
  )
}

export default FifthStep
