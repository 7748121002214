import {Typography} from 'antd';
import {SuccesMessage} from 'components';
import styled from 'styled-components';

const { Title } = Typography;

const TitleCustom = styled(Title)`
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 0 !important;
  max-width: 450px;
  text-align: center;
`;
const Message = () => {
  return (
    <SuccesMessage>
      <>
        <TitleCustom level={3}>
          ¡Gracias por compartir tu información!
        </TitleCustom>
        <TitleCustom level={3}>
          Nuestro equipo se contactará contigo en un máximo de 48 horas para
          continuar con el proceso.
        </TitleCustom>
      </>
    </SuccesMessage>
  );
};

export default Message;
