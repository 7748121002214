import {Button, Modal, Typography} from 'antd'
import {custom} from 'theme/palette'
import {FC} from 'react'
import styled from 'styled-components'
import Check from 'assets/checkWhite.svg'

const { Title, Text } = Typography

interface IModalProps {
  isOpen: boolean
  pruebaType: String
  onConfirmationModalCloseRequest(data: object): void
}
const CheckImage = styled('img')`
  margin: 0 15px 0 0;
  width: 12%;
  height: 12%;
`

const TitleCustom = styled(Title)`
  margin: 1rem 0 0 4rem;
  width: 70%;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  color: ${custom.secondary.light};
`

const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ButtonCustom = styled(Button)`
  margin: 20px 20px 20px 10px;
  width: 30%;
  height: 45px;
  border-radius: 8px;
`

const ConfirmationModal: FC<IModalProps> = ({
  isOpen,
  pruebaType,
  onConfirmationModalCloseRequest,
}) => {
  switch (pruebaType) {
    case 'paseAprod':
      return (
        <ModalContainer
          onCancel={onConfirmationModalCloseRequest}
          onOk={onConfirmationModalCloseRequest}
          visible={isOpen}
          footer={false}
          closeIcon
        >
          <TitleCustom level={2}>
            {' '}
            <CheckImage src={Check} alt="check" />
            ¡Solicitud de pase a producción enviada!
          </TitleCustom>
          <TextCustom>
            Te estamos confirmando el pase a producción en un plazo no mayor a
            24 horas.
          </TextCustom>
          <br />
          <ButtonCustom
            type="primary"
            onClick={onConfirmationModalCloseRequest}
          >
            Entendido
          </ButtonCustom>
        </ModalContainer>
      )
      break
    case 'soporte':
      return (
        <ModalContainer
          onCancel={onConfirmationModalCloseRequest}
          onOk={onConfirmationModalCloseRequest}
          visible={isOpen}
          footer={false}
          closeIcon
        >
          <TitleCustom level={2}>
            {' '}
            <CheckImage src={Check} alt="check" />
            ¡Mensaje enviado!
          </TitleCustom>
          <TextCustom>
            Estaremos contactandote en los proximos 60 minutos para ayudarte a
            resolverlo.
          </TextCustom>
          <br />
          <ButtonCustom
            type="primary"
            onClick={onConfirmationModalCloseRequest}
          >
            Entendido
          </ButtonCustom>
        </ModalContainer>
      )
      break
    default:
      return (
        <ModalContainer
          onCancel={onConfirmationModalCloseRequest}
          onOk={onConfirmationModalCloseRequest}
          visible={isOpen}
          footer={false}
          closeIcon
        >
          <TitleCustom level={2}>
            {' '}
            <CheckImage src={Check} alt="check" />
            ¡Solicitud de prueba enviada!
          </TitleCustom>
          <TextCustom>
            Te estamos confirmando la reunión en un plazo no mayor a 24 horas.
          </TextCustom>
          <br />
          <ButtonCustom
            type="primary"
            onClick={onConfirmationModalCloseRequest}
          >
            Entendido
          </ButtonCustom>
        </ModalContainer>
      )
      break
  }

  // return (
  //   <ModalContainer
  //     onCancel={onConfirmationModalCloseRequest}
  //     onOk={onConfirmationModalCloseRequest}
  //     visible={isOpen}
  //     footer={false}
  //     closeIcon
  //   >
  //     <TitleCustom level={2}>
  //       {' '}
  //       <CheckImage src={Check} alt="check" />
  //       ¡Solicitud de prueba enviada!
  //     </TitleCustom>
  //     <TextCustom>
  //       Te estamos confirmando la reunión en un plazo no mayor a 24 horas.
  //     </TextCustom>
  //     <br />
  //     <ButtonCustom type="primary" onClick={onConfirmationModalCloseRequest}>
  //       Entendido
  //     </ButtonCustom>
  //   </ModalContainer>
  // )
}

export default ConfirmationModal
