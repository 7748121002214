import styled from 'styled-components'
import {custom} from 'theme/palette'
import {Dispatch, FC, SelectHTMLAttributes, SetStateAction} from 'react'
import {Col, Select, Typography} from 'antd'

const { Option } = Select
const { Text } = Typography

interface Value {
  id: number | string
  email?:string
  name: string
  countryID?: number
}

export interface SelectProps extends SelectHTMLAttributes<HTMLInputElement> {
  label: string
  placeholder: string
  onSearch?(): void
  span: number
  keyName: string
  data: any
  setData: Dispatch<SetStateAction<any>>
  values?: Value[]
}

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${custom.secondary.dark};
`

const SelectCustom: any = styled(Select)`
  border-radius: 7px;
  font-size: 16px;
  height: 40px !important;
  background: #c4c4c4;
  margin-top: 8px;
  & > div {
    height: 45px !important;
    max-height: 45px !important;
    border-radius: 5px !important;
  }
  & input {
    height: 45px !important;
  }

  & .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
  & .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
`

const SelectContainer: FC<SelectProps> = (props) => {
  const {
    label,
    placeholder,
    onSearch,
    span,
    keyName,
    data,
    setData,
    values,
    ...rest
  } = props
  const enterSelectValue = (value: any) => {
    setData({ ...data, [keyName]: value })
  }

  return (
    <Col style={{ display: 'flex', flexDirection: 'column' }} span={span}>
      <Label>{label}</Label>
      <SelectCustom
        showSearch
        placeholder={placeholder}
        optionFilterProp="children"
        onSearch={() => {}}
        onChange={enterSelectValue}
        {...rest}
        filterOption={(input: string, option: { children: string }) =>
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {values?.map((value) => {
          return (
            <Option key={value.id} value={value.id}>
              {value.name}
            </Option>
          )
        })}
      </SelectCustom>
    </Col>
  )
}

export default SelectContainer
