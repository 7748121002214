export const data = [
    {
      key: '1',
      estado: 'GOTO_PICK',
      descripcion: 'El repartidor en camino a recolectar.',
    },
    {
      key: '2',
      estado: 'PICKING',
      descripcion: 'El pedido ha sido recolectado.',
    },
    {
      key: '3',
      estado: 'GOTO_DELIVER',
      descripcion: 'El repartidor se encuentra en camino al cliente final.',
    },
    {
      key: '4',
      estado: 'DELIVERED',
      descripcion: 'El pedido ha sido entregado al cliente final.',
    },
    {
      key: '5',
      estado: 'FAILED_PICK',
      descripcion: 'El pedido no se pudo recolectar.',
    },
    {
      key: '6',
      estado: 'FAILED_DROP',
      descripcion: 'El pedido no se pudo entregar al cliente final.',
    },
    {
      key: '7',
      estado: 'ARRIVED_PICK',
      descripcion:
        '(En desarrollo) El repartidor se encuentra cerca del punto de recolección.',
    },
    {
      key: '8',
      estado: 'ARRIVED_DELIVER',
      descripcion:
        '(En desarrollo) El repartidor se encuentra cerca del punto de entrega.',
    },
  ]