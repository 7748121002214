import {AppState} from 'reducers/state'
import {Button, Col, Row, Tooltip, Typography} from 'antd'
import {Select} from 'components'
import {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppService} from 'service'
import styled, {createGlobalStyle} from 'styled-components'
import {custom} from 'theme/palette'
import {ActionType} from 'actions/types'
import Info from 'assets/gray-info.svg'

const { Title } = Typography

interface FormContactProps {
  setHasContact: Dispatch<SetStateAction<Boolean>>
}

const FormContainer = styled('form')`
  background: ${custom.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
  margin-top: 1rem;
`
const TooltipCustom = createGlobalStyle`
  body {
    .ant-tooltip-inner {
      color: ${custom.secondary.dark};
      background-color: ${custom.white};
      width:320px;
      @media (max-width: 550px) {
        top: 70%;
        
        width: 150px;
      }
    }
  }
`
const ImageCustom = styled('img')`
  height: 20px;
  margin: 0 0 0 7px;
`
const FormContact = (props: FormContactProps) => {
  const { setHasContact } = props
  const [data, setData] = useState({ uuiContact: '' })
  const dispatch = useDispatch()
  const appService = AppService(dispatch)
  const dataInitialForm = useSelector<AppState>((state) => state.initialForm)
  const commercialList: any = useSelector<AppState>(
    (state) => state.commercialList
  )
  const service = AppService(dispatch)
  const sendDataEnterpriseFlow2 = async () => {
    await appService.sendDataEnterpriseFlow2(dataInitialForm)
  }
  const sendContactForm = (e: { preventDefault(): void }) => {
    e.preventDefault()
    sendDataEnterpriseFlow2()
  }

  useEffect(() => {
    setHasContact(
      commercialList ? data.uuiContact === commercialList[0].id : false
    )
    dispatch({ type: ActionType.SET_FORM, payload: data })
    // setIsVerified(verifyForm(data))
  }, [data])
  useEffect(() => {
    service.getCommercial()
  }, [])

  return (
    <FormContainer onSubmit={sendContactForm}>
      <Title level={3} style={{ fontWeight: '600' }}>
        Datos de contacto de Chazki
        <Tooltip
          placement="bottom"
          title="Personal de Chazki que te atiende directamente"
        >
          <TooltipCustom />
          <ImageCustom src={Info} alt="warning" />
        </Tooltip>
      </Title>

      <Row gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        {commercialList && (
          <Select
            span={24}
            label="Nombre de tu ejecutivo comercial"
            placeholder="Selecciona aquí"
            keyName="uuiContact"
            data={data}
            setData={setData}
            values={commercialList}
          />
        )}
      </Row>
      <Row justify="end" gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        <Col span={12}>
          <Button
            disabled={data.uuiContact === ''}
            type="primary"
            htmlType="submit"
            style={{ width: '100%', height: '44px', fontSize: '1rem' }}
            onClick={() => {
              dispatch({ type: ActionType.FINISH_FLOWS, payload: true })
            }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default FormContact
