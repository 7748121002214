import {Layout, Spin} from 'antd'
import {Header, Main, Navbar, Sidebar} from 'layouts'
import {useSelector} from 'react-redux'
import Dontenv from 'dotenv'
import styled from 'styled-components'
import {AppState} from 'reducers/state'

Dontenv.config()

const SpinnerContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
`
const LayoutCustom = styled(Layout)`
  margin: 0 0 0 250px;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const App = () => {
  const isFetching = useSelector<AppState>((state) => state.isFetching)

  return (
    <>
      {isFetching && (
        <SpinnerContainer>
          <Spin />
        </SpinnerContainer>
      )}
      <Layout>
        <Navbar />
        <Sidebar />
        <LayoutCustom>
          {/* className="site-layout" */}
          <Header />
          <Main />
        </LayoutCustom>
      </Layout>
    </>
  )
}

export default App
