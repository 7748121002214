import {Typography} from 'antd'
import {Alert, ModalCustom, VideoPlayer} from 'components'
import {useState} from 'react'
import styled from 'styled-components'
import {custom} from 'theme/palette'

const { Title, Text, Link } = Typography

const ContainerCustom = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ListContainer = styled('ul')`
  padding-left: 2rem;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`
const OlCustom = styled('ol')`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`
const TextCustomAlert = styled(Text)`
  margin: 10px 0 0 0;
  font-size: 16px;
  font-weight: 300;
  color: ${custom.secondary.light};
  @media (max-width: 425px) {
    font-size: 14px;
    font-weight: 200;
  }
  @media (max-width: 365px) {
    grid-gap: 0px;
  }
`

const TitleCustom = styled(Title)`
  margin: 0 0 0 0;
`

const data = {
  title: `Agendar prueba en
  ambiente de desarrollo`,
  body: `Solicita una fecha y hora para tu prueba.`,
}

const ThirdStep = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalCloseRequest = (): void => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <ModalCustom
        title={data.title}
        pruebaType={'dev'}
        body={data.body}
        isOpen={isModalOpen}
        onCloseRequest={onModalCloseRequest}
      />
      <ContainerCustom>
        <TitleCustom level={2}>3. Accede al ambiente de prueba</TitleCustom>

        <ListContainer>
          <OlCustom>
            <li>
              <TextCustom>
                Solicita tu prueba con nuestro equipo de soporte, seleccionando
                la fecha y hora{' '}
                <Link
                  onClick={() => {
                    onModalCloseRequest()
                  }}
                >
                  aquí
                </Link>{' '}
                . Recibirás una confirmación a tu solicitud en máximo 24 horas.
              </TextCustom>
            </li>
            <li>
              <TextCustom>
                El día acordado el equipo tech te entregará el link al ambiente
                de prueba.
              </TextCustom>
            </li>
            <li>
              <TextCustom>Para realizar tus pruebas necesitarás:</TextCustom>
            </li>
          </OlCustom>
          <li>
            <TextCustom>
              <TextCustom strong>
                {' '}
                Enterprise Key del ambiente de prueba
              </TextCustom>{' '}
              (clave única de la empresa que varía segun el ambiente de trabajo:
              prueba y producción). Conoce cómo encontrarla en el siguiente
              video:
            </TextCustom>
          </li>
        </ListContainer>

        <VideoPlayer url="https://www.youtube.com/watch?v=4XxgohFRl_4" />

        <Alert title="Importante">
          <TextCustomAlert>
            Importante: Para realizar las pruebas con nuestro equipo de soporte
            tienes que agendar la reunión previamente y recibir la confirmación.
          </TextCustomAlert>
        </Alert>
      </ContainerCustom>
    </>
  )
}

export default ThirdStep
