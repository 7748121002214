import Check from 'assets/check.svg';
import {FC, ReactNode} from 'react';
import styled from 'styled-components';

interface SuccesProps {
  children: ReactNode;
}

const CenteredChildren = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem;
`;

const AlignChildren = styled('div')`
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CheckImage = styled('img')`
  width: 75px;
  height: 75px;
`;

const SuccesMessage: FC<SuccesProps> = ({ children }) => {
  return (
    <CenteredChildren>
      <CheckImage src={Check} alt="check" />
      <AlignChildren>{children}</AlignChildren>
    </CenteredChildren>
  );
};

export default SuccesMessage;
