import styled, {createGlobalStyle} from 'styled-components'
import {Button, Col, Row, Tooltip, Typography} from 'antd'
import {custom} from 'theme/palette'
import {Input, Select} from 'components'
import {useEffect, useState} from 'react'
import {verifyForm} from './utils'
import {AppService} from 'service'
import {useDispatch, useSelector} from 'react-redux'
import {ActionType} from 'actions/types'
import {AppState} from 'reducers/state'
import Info from 'assets/gray-info.svg'
import {categoriesTest, citiesTest, documentTest, shipmentsPerMonth,} from './data'

const { Title } = Typography

const FormContainer = styled('form')`
  background: ${custom.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
  margin-top: 1rem;
`

const ImageCustom = styled('img')`
  height: 20px;
  margin: 0 0 0 7px;
`

const TooltipCustom = createGlobalStyle`
  body {
    .ant-tooltip-inner {
      color: ${custom.secondary.dark};
      background-color: ${custom.white};
      width:320px;
      @media (max-width: 550px) {
        top: 70%;
        
        width: 150px;
      }
    }
  }
`
const Form = () => {
  const countries: any = useSelector<AppState>((state) => state.countries)
  const dataInitialForm = useSelector<AppState>((state) => state.initialForm)
  const dispatch = useDispatch()
  const appService = AppService(dispatch)
  const [data, setData] = useState({
    country: '',
    city: null,
    businessName: '',
    documentType: '',
    documentNumber: '',
    category: '',
    numberShipments: '',
    contactName: '',
    phone: '',
    email: '',
  })
  const [isVerified, setIsVerified] = useState(false)
  const [cities, setCities] = useState<any>(citiesTest)
  const findEnterprise = async () => {
    await appService.getNintendoEnterprises(dataInitialForm)
  }
  const sendData = (e: { preventDefault(): void }) => {
    e.preventDefault()
    if (
      data.numberShipments !== shipmentsPerMonth[0].id &&
      data.numberShipments !== shipmentsPerMonth[1].id
    ) {
      findEnterprise()
    } else {
      dispatch({ type: ActionType.CHANGE_FLOW, payload: 3 })
    }
  }
  const enterInputValue = (e: { target: HTMLInputElement }) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const getCities = async () => {
    const cities = await appService.getCities(data.country)
    setCities(cities)
  }

  useEffect(() => {
    if (data.country !== '') {
      getCities()
    }
  }, [data.country])

  useEffect(() => {
    dispatch({ type: ActionType.SET_FORM, payload: data })
    setIsVerified(verifyForm(data))
  }, [data])

  useEffect(() => {
    appService.getCountries()
  }, [])

  return (
    <FormContainer onSubmit={sendData}>
      <Title level={3} style={{ fontWeight: '600' }}>
        Datos de la empresa
      </Title>
      <Row gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        <Select
          span={12}
          label="Pais"
          placeholder="Selecciona"
          keyName="country"
          data={data}
          setData={setData}
          values={countries}
        />
        <Select
          disabled={cities.length === citiesTest.length}
          span={12}
          label="Ciudad"
          placeholder="Selecciona"
          keyName="city"
          data={data}
          setData={setData}
          values={cities}
        />
        <Input
          label="Razón social"
          placeholder="Escribe aquí"
          span={24}
          name="businessName"
          onChange={enterInputValue}
          required
        />

        <Select
          span={12}
          label="Tipo de documento"
          placeholder="Selecciona"
          keyName="documentType"
          data={data}
          setData={setData}
          values={documentTest}
        />
        <Input
          label="N° de documento"
          placeholder="Escribe aquí"
          span={12}
          name="documentNumber"
          onChange={enterInputValue}
          required
        />

        <Select
          span={12}
          label="Categoría"
          placeholder="Selecciona"
          keyName="category"
          data={data}
          setData={setData}
          values={categoriesTest}
        />
        <Select
          span={12}
          label="N° de envíos al mes"
          placeholder="Escribe aquí"
          keyName="numberShipments"
          data={data}
          setData={setData}
          values={shipmentsPerMonth}
        />
      </Row>
      <Title level={3} style={{ fontWeight: '600', marginTop: '2rem' }}>
        Datos de contacto
        <Tooltip
          placement="bottom"
          title="Persona para coordinar el proceso de integración"
        >
          <TooltipCustom />
          <ImageCustom src={Info} alt="warning" />
        </Tooltip>
      </Title>
      <Row gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        <Input
          label="Nombre de contacto"
          placeholder="Escribe aquí"
          span={24}
          name="contactName"
          onChange={enterInputValue}
          required
        />

        <Input
          label="Celular"
          placeholder="Escribe aquí"
          span={12}
          name="phone"
          onChange={enterInputValue}
          required
        />
        <Input
          label="Correo electrónico"
          placeholder="Escribe aquí"
          span={12}
          name="email"
          onChange={enterInputValue}
          required
        />
      </Row>
      <Row justify="end" gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        <Col span={12}>
          <Button
            disabled={!isVerified}
            type="primary"
            style={{ width: '100%', fontSize: '1rem', height: '45.14px' }}
            htmlType="submit"
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default Form
