import {Button, DatePicker, Input, Modal, Row, Typography} from 'antd'
import {custom} from 'theme/palette'
import {useDispatch, useSelector} from 'react-redux'
import React, {FC, useState} from 'react'
import styled from 'styled-components'
import {ConfirmationModal} from 'components'
import {AppService} from 'service'
import 'moment/locale/es-mx'
import {AppState} from 'reducers/state'
import locale from 'antd/lib/date-picker/locale/es_ES'

const { Title, Text } = Typography
const { TextArea } = Input
function disabledDateTime() {
  return {
    disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 19, 20, 21, 22, 23],
  }
}

interface IModalProps {
  pruebaType: String
  title: String
  body: String
  isOpen: boolean
  onCloseRequest(data: object): void
}

const TitleCustom = styled(Title)`
  margin: 1rem 0 0 1rem;
  width: 70%;
`
const RowCustom = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
`
const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`

const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ButtonCustom = styled(Button)`
  // margin: 0 10px 0 10px;
  width: 30%;
  height: 45px;
  border-radius: 8px;
  @media (max-width: 425px) {
    width: 40%;
  }
`

const ModalCustom: FC<IModalProps> = ({
  title,
  body,
  pruebaType,
  isOpen,
  onCloseRequest,
}) => {
  const dispatch = useDispatch()
  const appService = AppService(dispatch)
  const dataInitialForm = useSelector<AppState>((state) => state.initialForm)
  const [datePicked, setdatePicked] = useState<String>('')
  const [supportMessage, setmsupportMessage] = useState('')
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)

  const onConfirmationModalCloseRequest = (): void => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen)
  }

  const modalsHandler = async (e: React.MouseEvent) => {
    switch (pruebaType) {
      case 'dev':
        await appService.sendDevTest(dataInitialForm, datePicked)
        break
      case 'prod':
        await appService.sendProdTest(dataInitialForm, datePicked)
        break
      case 'paseAprod':
        await appService.sendProdPass(dataInitialForm, datePicked)
        break
      case 'soporte':
        await appService.sendSoporte(dataInitialForm, supportMessage)
        break
    }
    onCloseRequest(e)
    onConfirmationModalCloseRequest()
  }

  switch (pruebaType) {
    case 'paseAprod':
      return (
        <>
          <ModalContainer
            onCancel={onCloseRequest}
            onOk={onCloseRequest}
            visible={isOpen}
            footer={false}
          >
            <RowCustom>
              <TitleCustom level={2}>{title}</TitleCustom>
            </RowCustom>
            <RowCustom>
              <TextCustom>{body}</TextCustom>
            </RowCustom>
            <br />
            <RowCustom>
              <ButtonCustom type="primary" ghost onClick={onCloseRequest}>
                Mejor luego
              </ButtonCustom>

              <ButtonCustom
                type="primary"
                onClick={(e) => {
                  modalsHandler(e)
                }}
              >
                Solicitar
              </ButtonCustom>
            </RowCustom>
          </ModalContainer>
          <ConfirmationModal
            pruebaType={pruebaType}
            isOpen={isConfirmationModalOpen}
            onConfirmationModalCloseRequest={onConfirmationModalCloseRequest}
          />
        </>
      )

      break
    case 'soporte':
      return (
        <>
          <ModalContainer
            onCancel={onCloseRequest}
            onOk={onCloseRequest}
            visible={isOpen}
            footer={false}
          >
            <RowCustom>
              <TitleCustom level={2}>{title}</TitleCustom>
            </RowCustom>
            <RowCustom>
              <TextCustom>{body}</TextCustom>
            </RowCustom>
            <br />
            <RowCustom>
              <TextArea
                rows={4}
                placeholder={'Escribe aqui'}
                maxLength={500}
                size="large"
                onChange={(e) => {
                  setmsupportMessage(e.target.value)
                }}
              />
            </RowCustom>
            <br />

            <RowCustom>
              <ButtonCustom type="primary" ghost onClick={onCloseRequest}>
                Mejor luego
              </ButtonCustom>

              <ButtonCustom
                type="primary"
                onClick={(e) => {
                  modalsHandler(e)
                }}
              >
                Solicitar
              </ButtonCustom>
            </RowCustom>
          </ModalContainer>
          <ConfirmationModal
            pruebaType={pruebaType}
            isOpen={isConfirmationModalOpen}
            onConfirmationModalCloseRequest={onConfirmationModalCloseRequest}
          />
        </>
      )
      break
    default:
      return (
        <>
          <ModalContainer
            onCancel={onCloseRequest}
            onOk={onCloseRequest}
            visible={isOpen}
            footer={false}
          >
            <RowCustom>
              <TitleCustom level={2}>{title}</TitleCustom>
            </RowCustom>
            <RowCustom>
              <TextCustom>{body}</TextCustom>
            </RowCustom>
            <RowCustom>
              <DatePicker
                locale={locale}
                minuteStep={5}
                disabledTime={disabledDateTime}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ format: 'HH:mm' }}
                onChange={(date, dateString) => {
                  setdatePicked(dateString)
                }}
                style={{
                  width: '70%',
                  marginTop: '10px',
                  marginBottom: '20px',
                }}
              />
            </RowCustom>
            <RowCustom>
              <ButtonCustom type="primary" ghost onClick={onCloseRequest}>
                Mejor luego
              </ButtonCustom>
              {datePicked.length > 0 ? (
                <ButtonCustom
                  type="primary"
                  onClick={(e) => {
                    modalsHandler(e)
                  }}
                >
                  Agendar
                </ButtonCustom>
              ) : (
                <ButtonCustom type="primary" disabled>
                  Agendar
                </ButtonCustom>
              )}
            </RowCustom>
          </ModalContainer>
          <ConfirmationModal
            pruebaType={pruebaType}
            isOpen={isConfirmationModalOpen}
            onConfirmationModalCloseRequest={onConfirmationModalCloseRequest}
          />
        </>
      )
      break
  }
}

export default ModalCustom
