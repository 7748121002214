interface DataForm {
  businessOwn: string
  howManyBusinesses: string
  country: string
  city: string
  aditionalComment: string
}

export const ValidationForm = (data: DataForm) => {
  if (
    data.businessOwn !== '' &&
    data.howManyBusinesses !== '' &&
    data.country !== '' &&
    data.city !== '' &&
    data.aditionalComment !== ''
  ) {
    return false
  } else {
    return true
  }
}
