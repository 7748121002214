import {Col, Typography} from 'antd'
import styled from 'styled-components'
import {custom} from 'theme/palette'

const { Title, Text, Link } = Typography

const ListContainer = styled('ul')`
  padding-left: 2rem;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`

const ColCustom = styled(Col)`
  padding-left: 35px;
`

const FirstStep = () => {
  return (
    <Col>
      <Title level={2}>1. Prepárate para la integración </Title>
      <ColCustom>
        <TextCustom>Para iniciar la integración asegúrate de:</TextCustom>
        <ListContainer>
          <li>
            <TextCustom>
              Contar con un <TextCustom strong>equipo técnico</TextCustom>, es
              decir el/los desarrollador/es con experiencia en integraciones y
              API.
            </TextCustom>
          </li>
          <li>
            <TextCustom>
              {' '}
              Revisar la documentación de la{' '}
              <Link
                href="https://chazki.github.io/cfunctions-public-documentation/module-WebServices.html#~uploadClientOrders"
                target="_blank"
              >
                {' '}
                API Reference{' '}
              </Link>{' '}
              con tu equipo técnico y recoge todas las dudas que surjan. Nuestro
              equipo de soporte te ayudará a resolverlas.
            </TextCustom>
          </li>
          <li>
            <TextCustom>
              La herramienta para pruebas de integraciones que sugerimos es{' '}
              <Link href="https://www.postman.com/" target="_blank">
                POSTMAN
              </Link>
            </TextCustom>
          </li>
        </ListContainer>
      </ColCustom>
    </Col>
  )
}

export default FirstStep
