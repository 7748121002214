import {SuccesMessage} from 'components'
import {Typography} from 'antd'
import {FifthStep, FirstStep, FourthStep, SecondStep, SeventhStep, SixthStep, ThirdStep,} from './sections'
import {Element} from 'react-scroll'
import styled from 'styled-components'

const { Title, Text } = Typography

const TitleCustom = styled(Title)`
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 0 !important;
  max-width: 450px;
  text-align: center;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
`

const StepsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
`

const FlowOne = () => {
  return (
    <>
      <SuccesMessage>
        <>
          <TitleCustom level={3}>
            ¡Pronto nos pondremos en contacto! <br />
            Entretanto, conoce más de nuestro proceso de integración.
          </TitleCustom>
        </>
      </SuccesMessage>
      <TextCustom style={{ fontSize: '20px', fontWeight: '300' }}>
        Así es nuestro proceso de integración:
      </TextCustom>
      <StepsContainer>
        <Element name="firstStep">
          <FirstStep />
        </Element>
        <Element name="secondStep">
          <SecondStep />
        </Element>
        <Element name="thirdStep">
          <ThirdStep />
        </Element>
        <Element name="fourthStep">
          <FourthStep />
        </Element>
        <Element name="fifthStep">
          <FifthStep />
        </Element>
        <Element name="sixthStep">
          <SixthStep />
        </Element>
        <Element name="seventhStep">
          <SeventhStep />
        </Element>
      </StepsContainer>
    </>
  )
}

export default FlowOne
