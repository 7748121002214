import {ActionType} from 'actions/types'
import {Typography} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'reducers/state'
import {Form} from 'sections'
import styled from 'styled-components'
import FlowOne from './FlowOne'
import FlowThree from './FlowThree'
import FlowTwo from './FlowTwo'

const { Text, Title } = Typography

const MainView = () => {
  const DivCustom = styled('div')`
    padding: 1 rem;
    @media (max-width: 375px) {
      margin: 0 20px;
    }
  `
  const CustomText = styled(Text)`
    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 300;
    }
    @media (max-width: 425px) {
      font-size: 18px;
      font-weight: 300;
    }
    @media (max-width: 375px) {
      margin: 0 20px;
      font-size: 18px;
      font-weight: 300;
    }
  `

  const flow = useSelector<AppState>((state) => state.flow)
  const dispatch = useDispatch()
  if (flow === 1) {
    // dispatch({ type: ActionType.FINISH_FLOWS, payload: true })
    dispatch({ type: ActionType.FINISH_FLOWS, payload: true })
    return <FlowOne />
  } else if (flow === 2) {
    return <FlowTwo />
  } else if (flow === 3) {
    return <FlowThree />
  }
  return (
    <>
      <DivCustom>
        <Title level={2}>¿Quieres integrarte?</Title>
      </DivCustom>
      <CustomText>
        Completa nuestro quiz y descubre cuál es la mejor opción para tu negocio
      </CustomText>
      <Form />
    </>
  )
}

export default MainView
