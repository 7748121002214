import {Typography} from 'antd'
import {useSelector} from 'react-redux'
import {AppState} from 'reducers/state'
import styled from 'styled-components'
import {FormContact, Message} from './sections'

const { Text } = Typography

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
`

const FlowThree = () => {
  const succes = useSelector<AppState>((state) => state.succes)
  return (
    <>
      {/* <Title level={2}>¿Quieres integrarte?</Title> */}
      <Container>
        {succes ? (
          <Message />
        ) : (
          <>
            <Text style={{ fontSize: '20px', fontWeight: '300' }}>
              Hola emprendedor! Nos encantaría conocerte más y crecer contigo.
              En este momento tenemos una solución que se ajusta a tu necesidad
              de acuerdo al número de envíos que nos has indicado (De 0 a 50
              envíos al mes).
            </Text>
            <Text style={{ fontSize: '20px', fontWeight: '300' }}>
              Así como tu, hemos acompañado a muchos emprendedores en su proceso
              de crecimiento, no sólo te ofrecemos ser tu apoyo logístico sino
              la plataforma que contribuya con tu crecimiento.
            </Text>
            <Text style={{ fontSize: '20px', fontWeight: '300' }}>
              Cuéntanos más sobre tu negocio llenando el siguiente formulario:
            </Text>{' '}
            <FormContact />
          </>
        )}
      </Container>
    </>
  )
}

export default FlowThree
