import React, {useState} from 'react'
import {Button, Drawer, Menu, Typography} from 'antd'
import {MenuOutlined} from '@ant-design/icons'
import Logo from 'assets/mobile-logo.svg'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {AppState} from 'reducers/state'
import {ReturnHomeModal} from 'components'

const { Text } = Typography
function Navbar() {
  const [visible, setVisible] = useState(false)
  const NavbarCustom = styled('nav')`
    background-color: white;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    @media (min-width: 769px) {
      display: none;
    }
  `
  const Image = styled('img')`
    height: 32px;
    margin: 15px 0 0 1rem;
  `
  const ButtonCustom = styled(Button)`
    margin: 15px 30px 0 0;
    float: right;
    @media (min-width: 992px) {
      display: none;
    }
  `
  const Title = styled('div')`
    margin: 12px 0;
  `
  const TextTitle = styled(Text)`
    font-size: 16px;
  `
  const enterpriseExits = useSelector<AppState>(
    (state) => state.enterpriseExits
  )

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const onConfirmationModalCloseRequest = (): void => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen)
  }
  return (
    <NavbarCustom>
      <ButtonCustom
        type="primary"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
      />

      <Drawer
        placement="left"
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <>
          {enterpriseExits ? (
            <>
              <Title>
                <TextTitle>Pasos para la integración</TextTitle>
              </Title>
              <Menu mode="inline">
                <Menu.Item key={1}>Prepárate para la integración.</Menu.Item>
                <Menu.Item key={2}>Pruebas sugeridas.</Menu.Item>
                <Menu.Item key={3}>Ambiente de prueba.</Menu.Item>
                <Menu.Item key={4}>Ambiente de producción.</Menu.Item>
                <Menu.Item key={5}>Pase a producción.</Menu.Item>
                <Menu.Item key={6}>Seguimiento y soporte.</Menu.Item>
              </Menu>
            </>
          ) : (
            <Menu mode="inline">
              <Menu.Item key={1}>¿Quieres integrarte?</Menu.Item>{' '}
            </Menu>
          )}
        </>
      </Drawer>
      <ReturnHomeModal
        isOpen={isConfirmationModalOpen}
        onConfirmationModalCloseRequest={onConfirmationModalCloseRequest}
      />
      <Image src={Logo} alt="logo" onClick={onConfirmationModalCloseRequest} />
    </NavbarCustom>
  )
}

export default Navbar
