import {Collapse, Table, Typography} from 'antd'
import styled from 'styled-components'
import {custom} from 'theme/palette'
import {DownCircleTwoTone, UpCircleTwoTone} from '@ant-design/icons'
import {data} from './data'
import {useState} from 'react'
import {ModalCustom} from 'components'

const { Panel } = Collapse
const { Title, Text, Link } = Typography

const TitleCustom = styled(Title)`
  margin: 10px 0 0 15px;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`
const LightTextCustom = styled(Text)`
  font-size: 15px;
  font-weight: 200;
  color: ${custom.secondary.light};
`

const ListContainer = styled('ol')`
  padding-left: 2rem;
`

const CustomTable = styled(Table)`
  margin-top: 10px;
`

const columns = [
  {
    title: 'Estado',
    dataIndex: 'estado',
    key: 'estado',
  },
  {
    title: 'Descripción',
    dataIndex: 'descripcion',
    key: 'descripcion',
  },
]

const SeventhStep = () => {
  const dataModal = {
    title: `¿En qué podemos ayudarte?`,
    body: `Escribenos aqui`,
    pruebaType: 'soporte',
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalCloseRequest = (): void => {
    setIsModalOpen(!isModalOpen)
  }

  return (
    <>
      <TitleCustom level={1} style={{ fontSize: '35px' }}>
        Preguntas frecuentes{' '}
      </TitleCustom>
      <Collapse
        ghost
        bordered={false}
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? <DownCircleTwoTone /> : <UpCircleTwoTone />
        }
      >
        <Panel
          header="¿Dónde encuentro el Enterprise Key?"
          key="1"
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          <LightTextCustom>
            El Enterprise Key es la credencial o llave de tu negocio en Chazki.
            Esta es muy importante pues permite dar acceso a tu negocio en
            nuestro sistema. Para encontrar la Enterprise Key puedes seguir
            estos pasos:
            <ListContainer>
              <li>
                Ingresa a la plataforma de ambiente de prueba o de producción
                (zelda.chazki.com)
              </li>
              <li>Selecciona el módulo de “Configuraciones”</li>
              <li>Ingresa al submódulo "Mi empresa"</li>
              <li>
                Ve a "Datos de interfaz" y copia la llave de empresa (Enterprise
                Key)
              </li>
            </ListContainer>
          </LightTextCustom>
        </Panel>
        <Panel
          header="¿Por qué el sistema no me permite ingresar a la plataforma?"
          key="2"
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          <LightTextCustom>
            En caso no puedes acceder a tu cuenta, verifica que tu credencial
            (usuario y contraseña) corresponda al ambiente de trabajo al que
            deseas acceder (de prueba o producción). Si el inconveniente
            persiste, contacta a tu ejecutivo de cuenta Chazki.
          </LightTextCustom>
        </Panel>
        <Panel
          header="¿Cómo puedo recuperar mi credencial (usuario y contraseña)?"
          key="3"
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          <LightTextCustom>
            Para recuperar tus credenciales contacta a tu ejecutivo de cuenta
            Chazki.
          </LightTextCustom>
        </Panel>

        <Panel
          header="¿Cuáles son los requerimientos para los Webhooks?"
          key="4"
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          <LightTextCustom>
            Los Webhooks te permiten estar al tanto de la evolución de tus
            envíos a través de notificaciones. Puedes programar y personalizar
            las notificaciones según tu preferencia. Para utulizar los Webhooks,
            debes contar con un servicio API disponible que pueda soportar la
            estructura que indicamos en nuestra{' '}
            <Link
              href="https://docs.google.com/document/d/1GiIK7iLZTjPlwtg2QOiBM_U0A18kRaShgyhLnR08K9w/edit?usp=sharing"
              target="_blank"
            >
              documentación
            </Link>{' '}
            .
          </LightTextCustom>
        </Panel>

        <Panel
          header="¿Qué estados de la orden voy a recibir en los webhooks?"
          key="5"
          style={{ fontSize: '18px', fontWeight: 'bold' }}
        >
          <LightTextCustom>
            Manejamos los siguientes estados para una orden:
          </LightTextCustom>
          <LightTextCustom>
            <CustomTable
              pagination={false}
              columns={columns}
              dataSource={data}
            />
          </LightTextCustom>
          <br />
        </Panel>
      </Collapse>

      <TextCustom>
        ¿Tienes alguna duda técnica sobre la integración? Enviala al siguiente
        correo:{' '}
        <Link href="mailto:integraciones@chazki.com" target="_blank">
          integraciones@chazki.com
        </Link>
      </TextCustom>
      <ModalCustom
        title={dataModal.title}
        pruebaType={dataModal.pruebaType}
        body={dataModal.body}
        isOpen={isModalOpen}
        onCloseRequest={onModalCloseRequest}
      />
      <TextCustom strong>
        ¿Aún no has completado el proceso satisfactoriamente?{' '}
      </TextCustom>
      <TextCustom>
        Escríbenos <Link onClick={onModalCloseRequest}>aqui</Link> y te
        ayudaremos.
      </TextCustom>
    </>
  )
}

export default SeventhStep
