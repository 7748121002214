const white = '#FFFFFF';
const black = '#000000';

export const custom = {
  secondary: {
    dark: black,
    main: '#595959',
    light: '#8C8C8C',
  },
  primary: {
    main: '#40A9FF',
    dark: '#307FE2',
    light: '#E6F7FF',
  },
  gray: {
    main: '#E5E5E5',
    dark: '#D9D9D9',
    light: '#F2F2F2',
  },
  fuschia: '#F178B6',
  red: {
    main: '#F5222D',
    light: '#FFF1F0',
  },
  white,
};
