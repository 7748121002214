import {useState} from 'react'
import {useSelector} from 'react-redux'
import {AppState} from 'reducers/state'
import {FormContact, Message} from './sections'


const FlowTwo = () => {
  const succes = useSelector<AppState>((state) => state.succes)
  const [hasContact, setHasContact] = useState<Boolean>(false)

  return (
    <>
      {succes ? (
        <Message hasContact={hasContact} />
      ) : (
        <FormContact setHasContact={setHasContact} />
      )}
    </>
  )
}

export default FlowTwo
