export const citiesTest = [
  { id: 1, name: 'Santiago', countryID: 1 },
  { id: 2, name: 'Viña del Mar', countryID: 1 },
  { id: 3, name: 'Puebla', countryID: 2 },
  { id: 4, name: 'Cuernavaca', countryID: 2 },
  { id: 5, name: 'Tijuana', countryID: 2 },
  { id: 11, name: 'Medellin', countryID: 3 },
  { id: 12, name: 'Bogota', countryID: 3 },
]
export const documentTest = [
  { id: '208', name: 'CUIT' },
  { id: '245', name: 'NIT' },
  { id: '206', name: 'RFC' },
  { id: '31', name: 'RUC' },
  { id: '246', name: 'RUT' },
]
export const categoriesTest = [
  { id: 'Banca', name: 'Banca' },
  { id: 'Calzado', name: 'Calzado' },
  { id: 'Casa & Decoración', name: 'Casa & Decoración' },
  { id: 'Comida & Bebidas', name: 'Comida & Bebidas' },
  { id: 'Consumer', name: 'Consumer' },
  { id: 'Documentos', name: 'Documentos' },
  { id: 'Electrónica', name: 'Electrónica' },
  {
    id: 'Herramientas para mejora del hogar',
    name: 'Herramientas para mejora del hogar',
  },
  { id: 'Libros', name: 'Libros' },
  { id: 'Logística', name: 'Logística' },
  { id: 'Manejo de cadena de frío', name: 'Manejo de cadena de frío' },
  { id: 'Marketplace', name: 'Marketplace' },
  { id: 'Mascotas', name: 'Mascotas' },
  { id: 'Materia prima', name: 'Materia prima' },
  { id: 'Moda & Accesorios', name: 'Moda & Accesorios' },
  { id: 'Niños & Bebes', name: 'Niños & Bebes' },
  { id: 'Oficina', name: 'Oficina' },
  { id: 'Perecederos', name: 'Perecederos' },
  { id: 'Otros', name: 'Otros' },
  { id: 'Retail & Consumer', name: 'Retail & Consumer' },
  { id: 'Salud & Belleza', name: 'Salud & Belleza' },
]
// export const shipmentsPerMonth = [
//   { id: 'De 0 a 50 envíos', name: 'De 0 a 50 envíos' },
//   { id: 'De 50 a 300 envíos', name: 'De 50 a 300 envíos' },
//   { id: 'De 301 a 1000 envíos', name: 'De 301 a 1000 envíos' },
//   { id: 'Más de 1000 envíos', name: 'Más de 1000 envíos' },
// ]
export const shipmentsPerMonth = [
  { id: 'De 0 a 50 envíos', name: 'De 0 a 50 envíos' },
  { id: 'De 50 a 300 envíos', name: 'De 50 a 300 envíos' },
  { id: 'De 301 a 2500 envíos', name: 'De 301 a 2500 envíos' },
  { id: 'Más de 2500 envíos', name: 'Más de 2500 envíos' },]
