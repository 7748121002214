import {Col, Typography} from 'antd'
import styled from 'styled-components'
import {custom} from 'theme/palette'

const { Title, Text } = Typography

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`

const ColCustom = styled(Col)`
  padding-left: 35px;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
`

const SixthStep = () => {
  return (
    <>
      <Col>
        <Title level={2}>6. Seguimiento y soporte</Title>
        <ColCustom>
          <TextCustom>
            Encuentra las respuestas a las dudas más frecuentes de nuestro
            proceso de integración.
          </TextCustom>
        </ColCustom>
      </Col>
    </>
  )
}

export default SixthStep
