import {FC} from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

interface VideoProps {
  url: string
  playing?: boolean
  controls?: boolean
}

const VideoContainer = styled('div')`
  position: relative;
  padding-top: 56.25%;
  margin: 20px 0 0 0;
  // width: 100%;
  // max-width: 960px;
`
const PlayerCustom = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const VideoPlayer: FC<VideoProps> = ({ url, playing, controls }) => {
  return (
    <VideoContainer>
      <PlayerCustom
        url={url}
        className="react-player"
        width="100%"
        height="100%"
        playing={playing}
        controls={controls}
      />
    </VideoContainer>
  )
}

VideoPlayer.defaultProps = {
  playing: false,
  controls: true,
}

export default VideoPlayer
