import {Typography} from 'antd'
import {SuccesMessage} from 'components'
import styled from 'styled-components'

const { Title, Text, Link } = Typography

interface MessageProps {
  hasContact: Boolean
}

const TitleCustom = styled(Title)`
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 0 !important;
  max-width: 450px;
  text-align: center;
`

const Message = (props: MessageProps) => {
  const { hasContact } = props
  return (
    <>
      <SuccesMessage>
        <>
          <TitleCustom level={3}>
            ¡Gracias por compartir tu información!
          </TitleCustom>
          <TitleCustom level={3}>
            Nuestro equipo se contactará contigo en un máximo de 48 horas para
            continuar con el proceso.
          </TitleCustom>
        </>
      </SuccesMessage>
      {hasContact ? (
        <Text style={{ fontSize: '20px', fontWeight: '300' }}>
          Puedes revisar nuestra{' '}
          <Link href="https://chazki.com/" target="_blank">
            página web
          </Link>{' '}
          para conocer más de Chazki y nuestros beneficios.
        </Text>
      ) : (
        <>
          {/* <Text style={{ fontSize: '20px', fontWeight: '300' }}>
            Tambien puedes contactarte directamente
          </Text>
          <PhoneContainer>
            {ContactData.map((data) => {
              return (
                <Phone
                  countryIcon={data.countryIcon}
                  country={data.country}
                  phone={data.phone}
                  mail={data.mail}
                />
              )
            })}
          </PhoneContainer> */}
        </>
      )}
    </>
  )
}

export default Message
