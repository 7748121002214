import type {Response} from 'models/response'
import {HEADERS} from 'globals'
import {StatusCode} from 'models/status-code'
import axios, {AxiosRequestConfig} from 'axios'
import {InitialForm} from 'models/Form'

export const AppAPI = () => {
  const getNintendoEnterprises = async (
    initialForm: InitialForm
  ): Promise<Response> => {
    const data = {
      documentType: initialForm.documentType,
      documentNumber: initialForm.documentNumber,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesNintendo`,
      headers: HEADERS,
      data: data,
    }
    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const getCommercial = async (): Promise<Response> => {
    const config: AxiosRequestConfig<any> = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/getCommercial`,
      headers: HEADERS,
    }
    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const getCountries = async (): Promise<Response> => {
    const config: AxiosRequestConfig<any> = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/getCountries`,
      headers: HEADERS,
    }
    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const getCities = async (countryID: string): Promise<Response> => {
    const config: AxiosRequestConfig<any> = {
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/getCities/${countryID}`,
      headers: HEADERS,
    }
    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const sendDataEnterprise = async (dataEnterprise: InitialForm) => {
    const dateToday = new Date()

    const data = {
      cityID: dataEnterprise.city,
      companyName: dataEnterprise.businessName,
      documentType: dataEnterprise.documentType,
      documentNumber: dataEnterprise.documentNumber,
      category: dataEnterprise.category,
      sendingShipmentsCounting: dataEnterprise.numberShipments,
      ownBusiness: dataEnterprise.businessOwn,
      aditionalComment: dataEnterprise.aditionalComment,
      shipmentProjection: dataEnterprise.shipmentProjection,
      affiliatedBusinessesCount: dataEnterprise.howManyBusinesses,
      createdAt: dateToday,
      contactID: dataEnterprise.uuiContact,
      enterpriseID: dataEnterprise.enterpriseID,
      contactName: dataEnterprise.contactName,
      contactPhone: dataEnterprise.phone,
      contactEmail: dataEnterprise.email,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const sendDataEnterpriseFlow2 = async (dataEnterprise: InitialForm) => {
    const dateToday = new Date()

    const data = {
      cityID: dataEnterprise.city,
      companyName: dataEnterprise.businessName,
      documentType: dataEnterprise.documentType,
      documentNumber: dataEnterprise.documentNumber,
      category: dataEnterprise.category,
      sendingShipmentsCounting: dataEnterprise.numberShipments,
      ownBusiness: dataEnterprise.businessOwn,
      aditionalComment: dataEnterprise.aditionalComment,
      shipmentProjection: dataEnterprise.shipmentProjection,
      affiliatedBusinessesCount: dataEnterprise.howManyBusinesses,
      createdAt: dateToday,
      contactID: dataEnterprise.uuiContact,
      enterpriseID: dataEnterprise.enterpriseID,
      contactName: dataEnterprise.contactName,
      contactPhone: dataEnterprise.phone,
      contactEmail: dataEnterprise.email,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic/Flow2`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }

  const sendDataEnterpriseFlow3 = async (dataEnterprise: InitialForm) => {
    const dateToday = new Date()

    const data = {
      cityID: dataEnterprise.city,
      companyName: dataEnterprise.businessName,
      documentType: dataEnterprise.documentType,
      documentNumber: dataEnterprise.documentNumber,
      category: dataEnterprise.category,
      sendingShipmentsCounting: dataEnterprise.numberShipments,
      ownBusiness: dataEnterprise.businessOwn,
      aditionalComment: dataEnterprise.aditionalComment,
      shipmentProjection: dataEnterprise.shipmentProjection,
      affiliatedBusinessesCount: dataEnterprise.howManyBusinesses,
      createdAt: dateToday,
      contactID: dataEnterprise.uuiContact,
      enterpriseID: dataEnterprise.enterpriseID,
      contactName: dataEnterprise.contactName,
      contactPhone: dataEnterprise.phone,
      contactEmail: dataEnterprise.email,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic/Flow3`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }

  const sendDevTest = async (
    dataEnterprise: InitialForm,
    date: String | undefined
  ) => {
    const data = {
      contactName: dataEnterprise.contactName,
      contactEmail: dataEnterprise.email,
      companyName: dataEnterprise.businessName,
      documentNumber: dataEnterprise.documentNumber,
      cityID: dataEnterprise.city,
      documentType: dataEnterprise.documentType,
      date,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic/devTest`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }

  const sendProdTest = async (
    dataEnterprise: InitialForm,
    date: String | undefined
  ) => {
    const data = {
      contactName: dataEnterprise.contactName,
      contactEmail: dataEnterprise.email,
      companyName: dataEnterprise.businessName,
      documentNumber: dataEnterprise.documentNumber,
      cityID: dataEnterprise.city,
      documentType: dataEnterprise.documentType,
      date,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic/prodTest`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const sendProdPass = async (
    dataEnterprise: InitialForm,
    date: String | undefined
  ) => {
    const data = {
      contactName: dataEnterprise.contactName,
      contactEmail: dataEnterprise.email,
      companyName: dataEnterprise.businessName,
      documentNumber: dataEnterprise.documentNumber,
      cityID: dataEnterprise.city,
      documentType: dataEnterprise.documentType,
      date,
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic/prodPass`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  const sendSoporte = async (
    dataEnterprise: InitialForm,
    supportMessage: String
  ) => {
    const dateToday = new Date()
    const data = {
      cityID: dataEnterprise.city,
      companyName: dataEnterprise.businessName,
      documentType: dataEnterprise.documentType,
      documentNumber: dataEnterprise.documentNumber,
      category: dataEnterprise.category,
      sendingShipmentsCounting: dataEnterprise.numberShipments,
      ownBusiness: dataEnterprise.businessOwn,
      aditionalComment: dataEnterprise.aditionalComment,
      shipmentProjection: dataEnterprise.shipmentProjection,
      affiliatedBusinessesCount: dataEnterprise.howManyBusinesses,
      createdAt: dateToday,
      contactID: dataEnterprise.uuiContact,
      enterpriseID: dataEnterprise.enterpriseID,
      contactName: dataEnterprise.contactName,
      contactPhone: dataEnterprise.phone,
      contactEmail: dataEnterprise.email,
      supportMessage: supportMessage
    }

    const config: AxiosRequestConfig<any> = {
      method: 'post',
      url: `${process.env.REACT_APP_API_URL}/enterprisesSonic/soporte`,
      headers: HEADERS,
      data: data,
    }

    const response = await axios(config)
    const bodyResponse = await response.data

    if (response.status === StatusCode.Ok) {
      return bodyResponse
    }

    const { errors } = response.request

    throw errors
  }
  return {
    getNintendoEnterprises,
    getCommercial,
    getCountries,
    getCities,
    sendDataEnterprise,
    sendDataEnterpriseFlow2,
    sendDataEnterpriseFlow3,
    sendDevTest,
    sendProdTest,
    sendProdPass,
    sendSoporte,
  }
}
