import styled from 'styled-components'
import {Layout} from 'antd'
import {custom} from 'theme/palette'
import MainView from 'views'

const { Content } = Layout

const ContentCustom = styled(Content)`
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  background: ${custom.white};
  overflow: initial;
  @media (max-width: 768px) {
    padding: 2rem 2.5rem;
  }
  @media (max-width: 425px) {
    padding: 2rem 1.5rem;
  }
  @media (max-width: 375px) {
    padding: 2rem 0.8rem;
  }
`

const Main = () => {
  return (
    <ContentCustom>
      <MainView />
    </ContentCustom>
  )
}

export default Main
