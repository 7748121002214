import {ActionType} from 'actions/types'
import {AppAPI} from 'api/app-api'
import type {AppAction} from 'actions/action'
import {Dispatch} from 'react'
import {message} from 'antd'

interface CommercialResponse {
  id: string
  email: string
  names: string
  lastname: string
}

export const AppService = (dispatch: Dispatch<AppAction>) => {
  const appAPI = AppAPI()
  const sendDataEnterprise = async (dataForm: any): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendDataEnterprise(dataForm)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se han registrado sus datos')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de formulario'
      )
      console.error(error)
    }
  }
  const sendDataEnterpriseFlow2 = async (dataForm: any): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendDataEnterpriseFlow2(dataForm)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se han registrado sus datos')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de formulario'
      )
      console.error(error)
    }
  }
  const sendDataEnterpriseFlow3 = async (dataForm: any): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendDataEnterpriseFlow3(dataForm)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se han registrado sus datos')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de formulario'
      )
      console.error(error)
    }
  }
  const sendDevTest = async (
    dataForm: any,
    date: String | undefined
  ): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendDevTest(dataForm, date)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se ha enviado su solicitud.')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de solicitud.'
      )
      console.error(error)
    }
  }

  const sendProdTest = async (
    dataForm: any,
    date: String | undefined
  ): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendProdTest(dataForm, date)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se ha enviado su solicitud.')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de solicitud.'
      )
      console.error(error)
    }
  }
  const sendProdPass = async (
    dataForm: any,
    date: String | undefined
  ): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendProdPass(dataForm, date)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se ha enviado su solicitud.')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de solicitud.'
      )
      console.error(error)
    }
  }

  const sendSoporte = async (
    dataForm: any,
    supportMessage: String
  ): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const response: any = await appAPI.sendSoporte(dataForm, supportMessage)
      dispatch({ type: ActionType.LOADING_ERROR })
      dispatch({ type: ActionType.SET_SUCCESS, payload: response.created })
      message.success('Se ha enviado su solicitud.')
      return response.created
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error, falla del sistema en el envío de solicitud.'
      )
      console.error(error)
    }
  }
  const getNintendoEnterprises = async (initialForm: any): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const enterpriseExists: any = await appAPI.getNintendoEnterprises(
        initialForm
      )
      if (enterpriseExists.exists) {
        dispatch({
          type: ActionType.SET_ID_ENTERPRISE,
          payload: enterpriseExists.information.enterpriseId,
        })

        const sendResponse = await sendDataEnterprise({
          ...initialForm,
          enterpriseID: enterpriseExists.information.enterpriseId,
        })

        dispatch({ type: ActionType.CHANGE_FLOW, payload: 1 })
        dispatch({
          type: ActionType.ENTERPRISE_EXISTS,
          payload: true,
        })
      } else {
        dispatch({ type: ActionType.CHANGE_FLOW, payload: 2 })
        dispatch({
          type: ActionType.ENTERPRISE_EXISTS,
          payload: false,
        })
      }
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error(
        'Ocurrió un error en el envío del formulario, intente nuevamente'
      )
      console.error(error)
    }
  }

  const getCommercial = async (): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const commercialList: any = await appAPI.getCommercial()
      const commercialListChanged = commercialList.map(
        (e: CommercialResponse) => {
          return {
            id: e.id,
            email: e.email,
            name: `${e.names} ${e.lastname}`,
          }
        }
      )
      const finalCommercialList: any = [
        {
          id: null,
          email: '',
          name: 'No tengo/recuerdo/encuentro mi contacto',
        },
        ...commercialListChanged,
      ]
      dispatch({
        type: ActionType.GET_COMMERCIAL_LIST,
        payload: finalCommercialList,
      })
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error('Ocurrió un error, falla del sistema')
      console.error(error)
    }
  }

  const getCountries = async (): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const countries: any = await appAPI.getCountries()
      dispatch({
        type: ActionType.SET_COUNTRIES,
        payload: countries,
      })
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error('Ocurrió un error, falla del sistema')
      console.error(error)
    }
  }

  const getCities = async (countryID: string): Promise<void> => {
    dispatch({ type: ActionType.LOADING })
    try {
      const cities: any = await appAPI.getCities(countryID)
      dispatch({ type: ActionType.LOADING_ERROR })
      return cities
    } catch (error) {
      dispatch({ type: ActionType.LOADING_ERROR })
      message.error('Ocurrió un error, falla del sistema')
      console.error(error)
    }
  }

  return {
    getNintendoEnterprises,
    getCommercial,
    getCountries,
    getCities,
    sendDataEnterprise,
    sendDataEnterpriseFlow2,
    sendDataEnterpriseFlow3,
    sendDevTest,
    sendProdTest,
    sendProdPass,
    sendSoporte,
  }
}
