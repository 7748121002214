import styled from 'styled-components'
import {Col, Input, Typography} from 'antd'
import {custom} from 'theme/palette'
import {FC, InputHTMLAttributes} from 'react'

const { Text } = Typography
const { TextArea } = Input
export interface TextAreaProps
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  placeholder?: string
  span?: number
  onChange: (e: { target: HTMLTextAreaElement }) => void
}

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${custom.secondary.dark};
`

const TextareaCustom = styled(TextArea)`
  border-radius: 5px;
  font-size: 16px;
  padding: 9px 11px;
  margin-top: 8px;
`

const TextAreaComponent: FC<TextAreaProps> = (props) => {
  const { label, span, placeholder, onChange, name } = props
  return (
    <Col span={span}>
      <Label>{label}</Label>
      <TextareaCustom
        placeholder={placeholder}
        onChange={onChange}
        name={name}
      />
    </Col>
  )
}

TextAreaComponent.defaultProps = {
  span: 12,
}

export default TextAreaComponent
