import {Button, Modal, Typography} from 'antd'
import {custom} from 'theme/palette'
import {FC} from 'react'
import styled from 'styled-components'

const { Title, Text} = Typography
interface IModalProps {
  isOpen: boolean
  onConfirmationModalCloseRequest(data: object): void
}

const TitleCustom = styled(Title)`
  margin: 1rem 0 0 4rem;
  width: 70%;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  width: 100%;
  color: ${custom.secondary.light};
`

const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ButtonCustom = styled(Button)`
  margin: 20px 20px 20px 10px;
  width: 30%;
  height: 45px;
  border-radius: 8px;
`
const ReturnHomeModal: FC<IModalProps> = ({
  isOpen,

  onConfirmationModalCloseRequest,
}) => {
  return (
    <div className="">
      <ModalContainer
        onCancel={onConfirmationModalCloseRequest}
        onOk={onConfirmationModalCloseRequest}
        visible={isOpen}
        footer={false}
        closeIcon
      >
        <TitleCustom level={2}>
          ¡Espera!
          <br />
          Aún no has terminado
        </TitleCustom>
        <TextCustom>
          Si sales del formulario, se perderán los datos registrados.
        </TextCustom>
        <br />
        <ButtonCustom
          type="primary"
          ghost
          onClick={onConfirmationModalCloseRequest}
        >
          Continuar aquí{' '}
        </ButtonCustom>

        <ButtonCustom type="primary" onClick={onConfirmationModalCloseRequest}>
          <a href="/">Ir a inicio</a>
        </ButtonCustom>
      </ModalContainer>
    </div>
  )
}

export default ReturnHomeModal
