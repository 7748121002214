import styled from 'styled-components'
import {Col, Layout, Menu, Typography} from 'antd'
import {custom} from 'theme/palette'
import Logo from 'assets/chazki-logo.svg'
import {useSelector} from 'react-redux'
import {AppState} from 'reducers/state'
import {useState} from 'react'
import {ReturnHomeModal} from 'components'
import {scroller} from 'react-scroll'

const { Sider, Header } = Layout
const { Text } = Typography

const SiderCustom = styled(Sider)`
  border-right: 1px solid ${custom.gray.main};
  height: 100vh;
  width: 250px !important;
  max-width: 250px !important;
  background: ${custom.white};
  overflow: auto;
  position: fixed;
  left: 0;
  @media (max-width: 768px) {
    display: none;
  }
`

const HeaderCustom = styled(Header)`
  background: ${custom.primary.dark};
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 1.5rem 0;
  & img {
    width: 100%;
    // max-width: 120px;
  }
`

const Content = styled(Col)`
  padding: 1rem 1rem 2rem 2rem;
`

const Title = styled('div')`
  margin: 12px 0;
`

const TextTitle = styled(Text)`
  font-size: 16px;
`

const ImgCustom = styled('img')`
  height: 32px;
  widtth: 240px;
`

const Sidebar = () => {
  const finishFlows = useSelector<AppState>((state) => state.finishFlows)

  const enterpriseExits = useSelector<AppState>(
    (state) => state.enterpriseExits
  )

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const onConfirmationModalCloseRequest = (): void => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen)
  }

  return (
    <Layout>
      {' '}
      <ReturnHomeModal
        isOpen={isConfirmationModalOpen}
        onConfirmationModalCloseRequest={onConfirmationModalCloseRequest}
      />
      <SiderCustom breakpoint="lg" collapsedWidth="0">
        <HeaderCustom>
          {finishFlows ? (
            <a href="/">
              <ImgCustom src={Logo} alt="logo" />
            </a>
          ) : (
            <ImgCustom
              src={Logo}
              alt="logo"
              onClick={onConfirmationModalCloseRequest}
            />
          )}
          {/* <ImgCustom
            src={Logo}
            alt="logo"
            onClick={onConfirmationModalCloseRequest}
          /> */}
        </HeaderCustom>
        <Content>
          <Title>
            <TextTitle>¿Quieres integrarte?</TextTitle>
          </Title>
          {enterpriseExits && (
            <>
              <Title>
                <TextTitle>Pasos para la integración</TextTitle>
              </Title>
              <Menu mode="vertical">
                <Menu.Item
                  key="1"
                  onClick={() => {
                    scroller.scrollTo('firstStep', { smooth: true })
                  }}
                >
                  <span>Prepárate para la integración.</span>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  onClick={() => {
                    scroller.scrollTo('secondStep', { smooth: true })
                  }}
                >
                  <span>Pruebas sugeridas.</span>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  onClick={() => {
                    scroller.scrollTo('thirdStep', { smooth: true })
                  }}
                >
                  <span>Ambiente de prueba.</span>
                </Menu.Item>
                <Menu.Item
                  key="4"
                  onClick={() => {
                    scroller.scrollTo('fourthStep', { smooth: true })
                  }}
                >
                  <span>Ambiente de producción.</span>
                </Menu.Item>
                <Menu.Item
                  key="5"
                  onClick={() => {
                    scroller.scrollTo('fifthStep', { smooth: true })
                  }}
                >
                  <span>Pase a producción.</span>
                </Menu.Item>
                <Menu.Item
                  key="6"
                  onClick={() => {
                    scroller.scrollTo('sixthStep', { smooth: true })
                  }}
                >
                  <span>Seguimiento y soporte.</span>
                </Menu.Item>
              </Menu>
            </>
          )}
        </Content>
      </SiderCustom>
    </Layout>
  )
}

export default Sidebar
