import styled from 'styled-components'
import {Col, Input, Typography} from 'antd'
import {custom} from 'theme/palette'
import {FC, InputHTMLAttributes} from 'react'

const { Text } = Typography

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  placeholder?: string
  span?: number
  name?: string
  onChange: (e: { target: HTMLInputElement }) => void
}

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${custom.secondary.dark};
`
const RequiredText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${custom.red.main};
`

const InputCustom = styled(Input)`
  border-radius: 5px;
  font-size: 16px;
  padding: 9px 11px;
  margin-top: 8px;
`

const InputContainer: FC<InputProps> = (props) => {
  const { label, span, placeholder, name, onChange, required } = props
  return (
    <Col span={span}>
      <Label>
        {label}{required ? <RequiredText>*</RequiredText> : ''}
      </Label>
      <InputCustom placeholder={placeholder} name={name} onChange={onChange} />
    </Col>
  )
}

InputContainer.defaultProps = {
  span: 12,
}

export default InputContainer
