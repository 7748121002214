import {Col, Typography} from 'antd'
import {Alert, ModalCustom, VideoPlayer} from 'components'
import {useState} from 'react'
import styled from 'styled-components'
import {custom} from 'theme/palette'

const { Title, Text, Link } = Typography

const ListContainer = styled('ul')`
  padding-left: 2rem;
`

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`
const TextCustomAlert = styled(Text)`
  font-size: 16px;
  font-weight: 300;
  color: ${custom.secondary.light};
  @media (max-width: 425px) {
    font-size: 14px;
    font-weight: 200;
  }
`
const OlCustom = styled('ol')`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`
const data = {
  title: `Agendar prueba en
  ambiente de producción`,
  body: `Solicita una fecha y hora para tu prueba.`,
  pruebaType: 'prod',
}

const FourthStep = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalCloseRequest = (): void => {
    setIsModalOpen(!isModalOpen)
  }
  return (
    <>
      <ModalCustom
        title={data.title}
        pruebaType={data.pruebaType}
        body={data.body}
        isOpen={isModalOpen}
        onCloseRequest={onModalCloseRequest}
      />
      <Col>
        <Title level={2}>4. Accede al ambiente de producción</Title>
        <TextCustom>Para realizar las pruebas en producción:</TextCustom>

        <ListContainer>
          <OlCustom>
            <li>
              <TextCustom>
                Solicita tu prueba con nuestro equipo de soporte, seleccionando
                la fecha y hora{' '}
                <Link
                  onClick={() => {
                    onModalCloseRequest()
                  }}
                >
                  aquí
                </Link>
                . Recibirás una confirmación a tu solicitud en máximo 24 horas.
              </TextCustom>
            </li>

            <li>
              <TextCustom>
                El día acordado el equipo tech te entregará el link al ambiente
                de producción
              </TextCustom>
            </li>
            <li>
              <TextCustom>Para realizar tus pruebas necesitarás:</TextCustom>
            </li>
          </OlCustom>
          <li>
            <TextCustom>
              Encuentra tu{' '}
              <TextCustom strong>Enterprise Key de producción</TextCustom> (esta{' '}
              <TextCustom strong>clave es diferente</TextCustom> a la del
              ambiente de prueba). Puedes encontrarla en tu{' '}
              <Link href="https://zelda.chazki.com/" target="_blank">
                plataforma de cliente{' '}
              </Link>{' '}
              (zelda.chazki.com) siguiendo las indicaciones del video.
            </TextCustom>
          </li>
        </ListContainer>

        <VideoPlayer url="https://www.youtube.com/watch?v=8ZPi45v0Ed0" />

        <Alert title="Importante">
          <TextCustomAlert>
            Para realizar las pruebas con nuestro equipo de soporte tienes que
            agendar la reunión previamente y recibir la confirmación.
          </TextCustomAlert>
        </Alert>
      </Col>
    </>
  )
}

export default FourthStep
