import {Col, Row, Typography} from 'antd'
import styled from 'styled-components'
import {custom} from 'theme/palette'
import CreacionOrdenes from 'assets/creacionOrdenes.svg'
import ConsultarOrdenes from 'assets/consultarOrdenes.svg'
import SeguirOrdenes from 'assets/seguirOrdenes.svg'
import FotosEntrega from 'assets/fotosEntrega.svg'
import Etiquetas from 'assets/etiquetas.svg'

const { Title, Text, Link } = Typography

const TextCustom = styled(Text)`
  font-size: 20px;
  font-weight: 300;
  color: ${custom.secondary.light};
`

const ColCustom = styled(Col)`
  display: flex;
  flex-direction: column;
`
const RowCustom = styled(Row)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
`

const TextBox = styled('div')`
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
  height: 150px;
  padding: 1rem;
  border-radius: 20px;
  text-align: center;
`

const TextCustomBox = styled(Text)`
  margin: 0 0 0 10px;
  font-size: 25px;
  text-align: left;
  @media (max-width: 1280px) {
    font-size: 22px;
  }
`
const BlueCircle = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${custom.primary.light};
  border-radius: 50%;
  box-shadow: 0 0 0 3px ${custom.primary.light};
`

const BigBlueCircle = styled(BlueCircle)`
  width: 100px;
  height: 100px;
`

const MediumBlueCircle = styled(BlueCircle)`
  width: 80px;
  height: 80px;
`

const BigImage = styled('img')`
  width: 70px;
  height: 70px;
`

const MediumImage = styled('img')`
  width: 50px;
  height: 50px;
`

const BigRectangle = styled(ColCustom)`
  margin: 1rem 0 0 0;
  width: 46%;
  border: 3px solid ${custom.primary.dark};
  border-radius: 10px;
  perspective: 1px;
  @media (max-width: 1154px) {
    width: 100%;
  }
`
const MediumRectangle = styled(ColCustom)`
  margin: 1rem 0 0 0;
  width: 29%;
  border: 3px solid ${custom.primary.dark};
  border-radius: 10px;
  @media (max-width: 1154px) {
    width: 100%;
  }
`

const SecondStep = () => {
  return (
    <Col>
      <Title level={2}>2. Elige las opciones de prueba de la API Chazki</Title>
      <ColCustom>
        <TextCustom>
          Con nuestra API Chazki puedes realizar las siguientes pruebas:
        </TextCustom>

        <RowCustom>
          <BigRectangle className="gutter-row">
            <Link
              href="https://chazki.github.io/cfunctions-public-documentation/module-WebServices.html#~uploadClientOrders"
              target="_blank"
            >
              <TextBox>
                <BigBlueCircle>
                  <BigImage src={CreacionOrdenes} alt="Creacion de ordenes" />
                </BigBlueCircle>
                <TextCustomBox>
                  Crear <br />
                  órdenes
                </TextCustomBox>
              </TextBox>
            </Link>
          </BigRectangle>

          <BigRectangle className="gutter-row">
            <Link
              href="https://chazki.github.io/cfunctions-public-documentation/module-WebServices.html#~fnOrderHistorialPublic"
              target="_blank"
            >
              <TextBox>
                <BigBlueCircle>
                  <BigImage src={ConsultarOrdenes} alt="Creacion de ordenes" />
                </BigBlueCircle>
                <TextCustomBox>
                  Consultar estado
                  <br /> de órdenes
                </TextCustomBox>
              </TextBox>
            </Link>
          </BigRectangle>
          <MediumRectangle className="gutter-row">
            <Link
              href="https://chazki.github.io/cfunctions-public-documentation/module-WebServices.html#~fnTrackOrderChazkiPosition"
              target="_blank"
            >
              <TextBox>
                <MediumBlueCircle>
                  <MediumImage src={SeguirOrdenes} alt="Creacion de ordenes" />
                </MediumBlueCircle>
                <TextCustomBox>
                  Seguir
                  <br /> órdenes
                </TextCustomBox>
              </TextBox>
            </Link>
          </MediumRectangle>
          <MediumRectangle className="gutter-row">
            <Link
              href="https://chazki.github.io/cfunctions-public-documentation/module-WebServices.html#~fnOrderImages"
              target="_blank"
            >
              <TextBox>
                <MediumBlueCircle>
                  <MediumImage src={FotosEntrega} alt="Creacion de ordenes" />
                </MediumBlueCircle>
                <TextCustomBox>
                  Ver fotos de <br />
                  entrega de
                  <br /> órdenes
                </TextCustomBox>
              </TextBox>
            </Link>
          </MediumRectangle>
          <MediumRectangle className="gutter-row">
            <Link
              href="https://chazki.github.io/cfunctions-public-documentation/module-WebServices.html#~createPDFByOrders"
              target="_blank"
            >
              <TextBox>
                <MediumBlueCircle>
                  <MediumImage src={Etiquetas} alt="Creacion de ordenes" />
                </MediumBlueCircle>
                <TextCustomBox>
                  Generar <br /> etiquetas
                </TextCustomBox>
              </TextBox>
            </Link>
          </MediumRectangle>
        </RowCustom>
      </ColCustom>
    </Col>
  )
}

export default SecondStep
