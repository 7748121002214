interface Form {
  country: string
  city: null | number
  businessName: string
  documentType: string
  documentNumber: string
  category: string
  numberShipments: string
  contactName: string
  phone: string
  email: string
}

export const verifyForm = (data: Form) => {
  if (
    data.country !== null &&
    data.city !== null &&
    data.businessName.length >= 3 &&
    data.documentType !== '' &&
    data.documentNumber.length >= 6 &&
    data.category.length >= 3 &&
    data.numberShipments !== '' &&
    data.contactName.length >= 3 &&
    data.phone.length >= 6 &&
    data.email.length >= 6
  ) {
    return true
  } else {
    return false
  }
}
