import {CommercialList} from 'models/commercial'
import {Countries, InitialForm} from 'models/Form'

export interface AppState {
  initialForm: InitialForm
  finishFlows: boolean
  isFetching: boolean
  enterpriseExits: null | boolean
  searchEnterpriseError: boolean
  inMiniEnterprise: null | boolean
  flow: 1 | 2 | 3 | null
  commercialList: CommercialList | null
  countries: Countries | null
  succes: boolean
}

export const initialState: AppState = {
  initialForm: {
    country: 0,
    city: null,
    businessName: '',
    documentType: '',
    documentNumber: '',
    category: '',
    numberShipments: '',
    contactName: '',
    phone: '',
    email: '',
    businessOwn: '',
    shipmentProjection: '',
    aditionalComment: '',
    isAssociated: '',
    howManyBusinesses: '',
    uuiContact: null,
    enterpriseID: null,
  },
  finishFlows: false,
  isFetching: false,
  enterpriseExits: null,
  searchEnterpriseError: false,
  inMiniEnterprise: null,
  flow: null,
  commercialList: null,
  countries: null,
  succes: false,
}
