import {ActionType} from 'actions/types'
import {Button, Col, Row, Typography} from 'antd'
import {Select, TextArea} from 'components'
import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {AppState} from 'reducers/state'
import {AppService} from 'service'
import styled from 'styled-components'
import {custom} from 'theme/palette'
import {ValidationForm} from './utils'

const { Title } = Typography
const citiesTest = [
  { id: 1, name: 'Santiago', countryID: 1 },
  { id: 2, name: 'Viña del Mar', countryID: 1 },
  { id: 3, name: 'Puebla', countryID: 2 },
  { id: 4, name: 'Cuernavaca', countryID: 2 },
  { id: 5, name: 'Tijuana', countryID: 2 },
  { id: 11, name: 'Medellin', countryID: 3 },
  { id: 12, name: 'Bogota', countryID: 3 },
]
const ownBusiness = [
  { id: 'Negocio Propio', name: 'Negocio Propio' },
  {
    id: 'Estoy asociado con otros negocios',
    name: 'Estoy asociado con otros negocios',
  },
]

const howBusinesses = [
  { id: '1 a 4', name: '1 a 4' },
  { id: '4 a más', name: '4 a más' },
]

const shipmentProjection = [
  { id: 'La misma que tenemos ahora', name: 'La misma que tenemos ahora' },
  {
    id: 'Crecer un 20 a 30%',
    name: 'Crecer un 20 a 30%',
  },
  {
    id: 'Crecer un 50%',
    name: 'Crecer un 50%',
  },
]

const FormContainer = styled('form')`
  background: ${custom.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
  margin-top: 1rem;
`

const FormContact = () => {
  const dataInitialForm = useSelector<AppState>((state) => state.initialForm)
  const countries: any = useSelector<AppState>((state) => state.countries)
  const [data, setData] = useState({
    businessOwn: '',
    howManyBusinesses: '',
    shipmentProjection: '',
    country: '',
    city: '',
    aditionalComment: '',
  })

  const sendDataEnterpriseFlow3 = async () => {
    await appService.sendDataEnterpriseFlow3(dataInitialForm)
  }

  const sendForm = (e: { preventDefault(): void }) => {
    e.preventDefault()
    sendDataEnterpriseFlow3()
  }

  const [cities, setCities] = useState<any>(citiesTest)

  const dispatch = useDispatch()
  const appService = AppService(dispatch)

  const getCities = async () => {
    const cities = await appService.getCities(data.country)
    setCities(cities)
  }

  const setCommentValue = (e: { target: { name: string; value: string } }) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    if (data.country !== '') {
      getCities()
    }
  }, [data.country])

  useEffect(() => {
    dispatch({ type: ActionType.SET_FORM, payload: data })
  }, [data])

  return (
    <FormContainer onSubmit={sendForm}>
      <Title level={3} style={{ fontWeight: '600' }}>
        Sobre tu negocio
      </Title>

      <Row gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        <Select
          span={24}
          label="¿Es negocio propio y/o estás asociado con otros negocios o microempresarios?"
          placeholder="Selecciona"
          keyName="businessOwn"
          data={data}
          setData={setData}
          values={ownBusiness}
        />

        <Select
          span={24}
          label="¿Cuantos negocios quieres afiliar?"
          placeholder="Selecciona"
          keyName="howManyBusinesses"
          data={data}
          setData={setData}
          values={howBusinesses}
        />

        <Select
          span={24}
          label="¿Cuál es tu proyección de envios en 3 meses?"
          placeholder="Selecciona"
          keyName="shipmentProjection"
          data={data}
          setData={setData}
          values={shipmentProjection}
        />

        <Select
          span={12}
          label="Pais"
          placeholder="Selecciona"
          keyName="country"
          data={data}
          setData={setData}
          values={countries}
        />
        <Select
          disabled={cities.length === citiesTest.length}
          span={12}
          label="Ciudad"
          placeholder="Selecciona"
          keyName="city"
          data={data}
          setData={setData}
          values={cities}
        />

        <TextArea
          span={24}
          label="¿Te gustaría comentarnos algo más sobre tu negocio?"
          placeholder="Escribe aquí"
          onChange={setCommentValue}
          name="aditionalComment"
        />
      </Row>
      <Row justify="end" gutter={[24, 24]} style={{ marginTop: '1.5rem' }}>
        <Col span={12}>
          <Button
            disabled={ValidationForm(data)}
            type="primary"
            style={{ width: '100%', fontSize: '1rem', height: '45px' }}
            htmlType="submit"
            onClick={() => {
              dispatch({ type: ActionType.FINISH_FLOWS, payload: true })
            }}
          >
            Enviar
          </Button>
        </Col>
      </Row>
    </FormContainer>
  )
}

export default FormContact
