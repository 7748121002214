import {Typography} from 'antd'
import RedWarning from 'assets/redwarning.svg'
import {FC, ReactNode} from 'react'
import styled from 'styled-components'
import {custom} from 'theme/palette'

const { Text } = Typography

interface AlertProps {
  title: string
  children: ReactNode
}

const RowCustom = styled('div')`
  width: 100%;
  background: ${custom.red.light};
  border: 1px solid ${custom.red.main};
  display: flex;
  flex-direction: row;
  grid-gap: 1.5rem;
  padding: 12px 40px 12px 20px;
  margin: 40px 0 0 0;
  box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  @media (max-width: 425px) {
    padding: 12px 20px 12px 20px;
  }
`

const ColCustom = styled('div')`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  @media (max-width: 425px) {
    grid-gap: 0px;
  }
 
`

const ImageCustom = styled('img')`
  height: 20px;
  margin: 7px 0;
`
const TextTitle = styled(Text)`
  font-size: 20px;
`

const AlertComponent: FC<AlertProps> = ({ title, children }) => {
  return (
    <RowCustom>
      <ColCustom>
        <ImageCustom src={RedWarning} alt="warning" />{' '}
      </ColCustom>
      <ColCustom>
        <TextTitle strong>{title}</TextTitle>
        {children}
      </ColCustom>
    </RowCustom>
  )
}

export default AlertComponent
