import styled from 'styled-components'
import {Layout, Typography} from 'antd'
import Driver from 'assets/driver.png'
import Driver2 from 'assets/driver2.svg'
import {custom} from 'theme/palette'

const { Header } = Layout
const { Title } = Typography

const Head = styled(Header)`
  height: 175px;
  position: relative;
  background: ${custom.white};
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  border-bottom: 1px solid ${custom.gray.main};
  padding: 2rem 2rem 0;
`
const TextTitle = styled(Title)`
  color: ${custom.secondary.dark} !important;
  font-weight: 700 !important;
  position: absolute;
  top: 60%;
  transform: translateY(-75%);
  z-index: 1;
  left: 4rem;
  @media (max-width: 854px) {
    font-weight: 700 !important;
    top: 70%;
    left: 4rem;
    width: 180px;
  }
  @media (max-width: 496px) {
    left: 3rem;
    width: 150px;
  }
  @media (max-width: 356px) {
    // top: 70%;
    left: 1rem;
    // width: 115px;
    font-size: 25px;
  }
`

const Image = styled('div')`
  height: 100%;
  & img {
    height: 100%;
  }
  @media (max-width: 768px) {
    height: 110%;
    & img {
      height: 100%;
    }
  }
  @media (max-width: 570px) {
    display: none;
  }
`

const Image2 = styled('div')`
  display: none;
  @media (max-width: 570px) {
    display: contents;
    position: absolute;
    height: 150%;

    & img {
      height: 120%;
    }
  }
`

const HeaderCustom = () => {
  return (
    <Head>
      <TextTitle level={2}>
        Optimiza tus <br />
        envíos con Chazki
      </TextTitle>
      <Image>
        <img src={Driver} alt="driver" />
      </Image>
      <Image2>
        <img src={Driver2} alt="driver2" />
      </Image2>
    </Head>
  )
}

export default HeaderCustom
